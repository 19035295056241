// extracted by mini-css-extract-plugin
export var ArtistDescription = "Toxic-module--ArtistDescription--+LdFk";
export var ArtistInfos = "Toxic-module--ArtistInfos--EuIX+";
export var ButtonWrapper = "Toxic-module--ButtonWrapper --JFMiM";
export var CardWrapper = "Toxic-module--CardWrapper--A6ZS-";
export var CarrouselWrapper2 = "Toxic-module--CarrouselWrapper2--RpTxA";
export var Citations = "Toxic-module--Citations--52NG-";
export var DescriptionWrapper = "Toxic-module--DescriptionWrapper--469RB";
export var ImageWrapper = "Toxic-module--ImageWrapper--7tmuW";
export var LinkWrapper = "Toxic-module--LinkWrapper--np6N8";
export var MobileProtrait = "Toxic-module--MobileProtrait--fQplq";
export var More = "Toxic-module--More--5ddg4";
export var MoreButton = "Toxic-module--MoreButton--xuohE";
export var NameWrapper = "Toxic-module--NameWrapper--Qv3FY";
export var PdpWrapper = "Toxic-module--PdpWrapper--jUdWJ";
export var PhotosWrapper = "Toxic-module--PhotosWrapper--LrZQ7";
export var ProfilWrapper = "Toxic-module--ProfilWrapper--pxEqq";
export var TitleWrapper = "Toxic-module--TitleWrapper--Bweiv";
export var Wrapper = "Toxic-module--Wrapper--MYJXi";
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Toxic.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import { ListWrapper } from "../../Expositions/Expositions.module.css";
import EventCard from "../../../../components/EventCard";
import ToxicPng1 from "../../../../res/Photos site/Toxic/Toxic by Anne Eveillard.jpg"
import ToxicPng2 from "../../../../res/Photos site/Toxic/Toxic, Jean Michel Basquiat (2).jpg"
import ToxicPng3 from "../../../../res/Photos site/Toxic/Kool_Koor,_Aone___Toxic_-_September_1982._Photograph_©_Mark_Baron..jpg"
import ToxicPng4 from "../../../../res/Photos site/Toxic/Toxic, Jean Michel Basquiat.jpg"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/toxic/past-present-1.jpeg"
import PastPresent2 from "../../../../res/toxic/past-present-2.jpg"
import PastPresent3 from "../../../../res/toxic/past-present-3.jpg"
import Pdp from "../../../../res/toxic/portrait.png"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Toxic",
  name: "Toxic",
  description:"Toxic, de son vrai nom Torrick Ablack, né en 1965 dans le Bronx à New York, est un artiste pionnier du mouvement graffiti des années 1980. Proche de Kool Koor et d’A-One, il commence dès ses treize ans à graffer des trains et des murs dans New York. Après sa rencontre avec Jean-Michel Basquiat en 1983, il se met à produire sur toile tout en gardant son lettrage très travaillé et ses recherches chromatiques puissantes qui lui étaient propres. Il crée avec Jean-Michel Basquiat et Rammellzee un groupe appelé : « The Hollywood Africans » pour lutter contre les stéréotypes afro américains dans l’industrie de l’art. Toxic est l’un des derniers chefs de file du mouvement graffiti encore en vie. Il participe notamment à l’exposition Writing the Future : Basquiat and the Hip-Hop Generation au Boston Museum of Arts en 2020.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Toxic.",
  photos: [
    { src: PastPresent1, name: "Toxic" },
    { src: PastPresent2, name: "Toxic" },
    { src: PastPresent3, name: "Toxic" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: [
    "« Avec Basquiat et Rammellzee, nous étions les ‘Hollywood Africains’ »",
    "« On voulait surtout changer l’histoire. Au début des années 1980, il faut se rendre compte qu’il n’y avait pas d’artistes noirs dans les galeries et les musées aux Etats-Unis, c’était très rare. On voulait changer ça. Avec Jean, Rammellzee, Dondi et A-One, on en parlait beaucoup. On vivait tous à cinq minutes les uns des autres dans l’East Village, on se voyait tous les jours »"
  ]
};


const Toxic = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>TOXIC <h2 style={{paddingLeft: "16px"}}>(Torrick Ablack - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1965</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />          
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Torrick Ablack aka Toxic: Meanwhile in 2020, Galerie Géraldine Zberro, Paris, France, November.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Torrick «TOXIC» Ablack - Project 48-17/18, ArtCan Gallery, Marseille, France, October 12th to November 16th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Get in where you fit in, Galerie Géraldine Zberro, Paris, France, June 16th - 27th.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet - septembre2022.</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Ghost DNA - exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 7th - September 4th.</p>
          <p>- Writing the Future. Basquiat and the Hip-Hop Generation, Museum of fine arts, Boston, USA October 18th - July 25th</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Writing the Future: Basquiat and the Hip Hop Generation, Museum of Fine Arts Boston, Boston, Massachusetts, USA, October 18th, 2020 - July 25th, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- We Are Back, ArtCan Gallery, Marseille, France, April 6th - May 12th.</p>
          <p>- Papers and Wood, Audrey Hatchikian Gallery, Paris, France, July 4th, 2017 - August 31th, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- En mémoire des Hollywood Africans (Toxic, Basquiat, Rammellzee), Taglialatella Galleries, Paris, France, May 19th - June 21st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Glamorous Graffiti: Basquiat, Crash, Haring & more, Nassau County Museum of Art, Roslyn, New York, USA, March 19th - June 10th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Graffiti, New York 80’s, Galerie Jérôme de Noirmont, Paris, France, May 27th - July 20th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Tag au Grand Palais (Collections Gallizia), Grand Palais. Paris, France, March 27th - April 26th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Fashion Moda presents: A-ONE, TOXIC, KOOR, Fashion Moda, Bronx, New York, USA, opening May 18th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Post-Graffiti, Sidney Janis Gallery, New York, USA, December 1th - 31th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p id="end">- Ikonoclast Panzerism Versus Tricnology, Squat Theatre, one night benefit organized by Stephen Norton, New York, USA, October 30th.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Toxic;